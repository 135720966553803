define("ember-models-table/components/models-table/themes/semanticui/select", ["exports", "ember-models-table/components/models-table/select", "ember-models-table/templates/components/models-table/select"], function (_exports, _select, _select2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _select.default.extend({
    layout: _select2.default,
    classNames: ['ui fluid dropdown'],
    classNameBindings: ['options.length::disabled']
  });

  _exports.default = _default;
});