define("ember-component-css/instance-initializers/route-styles", ["exports", "ember-component-css/utils/init-route-styles"], function (_exports, _initRouteStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  // This file is removed from the build in Ember < 3.6
  function initialize(appInstance) {
    var router = appInstance.lookup('service:router');
    router.on('routeDidChange', function (_ref) {
      var to = _ref.to;

      if (likeRouteInfo(to)) {
        (0, _initRouteStyles.default)(appInstance, nestedRouteNames(to));
      }
    });
    router.on('routeWillChange', function (_ref2) {
      var to = _ref2.to,
          isActive = _ref2.isActive;

      if (likeRouteInfo(to)) {
        if (/_loading$/.test(to.name) && isActive) {
          var routeNames = nestedRouteNames(to) // loading route names are set with an _loading even though
          // their path is -loading
          .map(function (name) {
            return name.replace(/_loading$/, '-loading');
          });
          (0, _initRouteStyles.default)(appInstance, routeNames);
        }
      }
    });
  }

  function nestedRouteNames(_ref3) {
    var name = _ref3.name,
        parent = _ref3.parent;
    var routeNames = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    routeNames.push(name);

    if (parent) {
      return nestedRouteNames(parent, routeNames);
    }

    return routeNames;
  }

  function likeRouteInfo(info) {
    return info && _typeof(info) === 'object' && info.hasOwnProperty('name');
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});