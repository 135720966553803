define("ember-models-table/templates/components/models-table/page-size-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2t3oapja",
    "block": "{\"symbols\":[\"pss\",\"&default\"],\"statements\":[[6,[37,8],[[30,[36,7],null,[[\"select\",\"themeInstance\",\"pageSizeOptions\",\"pageSize\"],[[30,[36,2],[[35,0,[\"components\",\"select\"]]],[[\"options\",\"value\",\"type\",\"themeInstance\",\"class\"],[[35,5],[35,4],[35,6],[35,0],\"changePageSize\"]]],[35,0],[35,5],[35,4]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"form\"],[15,0,[34,0,[\"form\"]]],[12],[2,\"\\n      \"],[10,\"div\"],[15,0,[34,0,[\"formElementWrapper\"]]],[12],[2,\"\\n        \"],[10,\"label\"],[15,\"for\",[34,1]],[12],[1,[35,0,[\"messages\",\"rowsCount\"]]],[13],[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"select\"]]],[[\"id\"],[[35,1]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"inputId\",\"component\",\"if\",\"pageSize\",\"pageSizeOptions\",\"type\",\"hash\",\"with\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/page-size-select.hbs"
    }
  });

  _exports.default = _default;
});