define("ember-models-table/templates/components/models-table/cell-edit-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TDrszbO1",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[15,0,[34,0,[\"cancel-row-button\"]]],[15,\"onclick\",[30,[36,1],[[32,0],\"cancelClicked\"],null]],[14,4,\"button\"],[12],[2,\"\\n    \"],[1,[34,3]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"button\"],[15,0,[34,0,[\"save-row-button\"]]],[15,\"onclick\",[30,[36,1],[[32,0],\"saveClicked\"],null]],[14,4,\"button\"],[12],[2,\"\\n    \"],[1,[34,4]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"button\"],[15,0,[34,0,[\"edit-row-button\"]]],[15,\"onclick\",[30,[36,1],[[32,0],\"editClicked\"],null]],[14,4,\"button\"],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"action\",\"editButtonLabel\",\"cancelButtonLabel\",\"saveButtonLabel\",\"isEditRow\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell-edit-toggle.hbs"
    }
  });

  _exports.default = _default;
});