define("ember-models-table/templates/components/models-table/themes/ember-semanticui/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0ElbrTjR",
    "block": "{\"symbols\":[\"execute\",\"mapper\",\"opt\",\"&default\"],\"statements\":[[6,[37,17],null,[[\"class\",\"selected\",\"placeholder\",\"onChange\"],[[30,[36,16],[\"selection \",[30,[36,14],[[35,15],\"compact\"],null],[30,[36,14],[[35,6,[\"length\"]],\" disabled\"],null]],null],[35,1],[35,13,[\"filterPlaceholder\"]],[30,[36,3],[[32,0],\"updateValue\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"default text\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,\"data-id\",[30,[36,4],[[32,3,[\"value\"]]],null]],[15,\"data-value\",[30,[36,5],[[32,2],[32,3,[\"value\"]]],null]],[14,0,\"item\"],[12],[2,\"\\n        \"],[1,[32,3,[\"label\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,12],[[30,[36,11],[[30,[36,10],[[35,1],\"\"],null],[35,9]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[31,[\"clearFilterIcon \",[34,0,[\"clearFilterIcon\"]]]]],[15,\"onclick\",[30,[36,3],[[32,0],[30,[36,2],[[35,1]],null],\"\"],null]],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[18,4,null]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"value\",\"mut\",\"action\",\"stringify\",\"map-value\",\"options\",\"-track-array\",\"each\",\"clearable\",\"not-eq\",\"and\",\"if\",\"column\",\"unless\",\"wide\",\"concat\",\"ui-dropdown\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-semanticui/select.hbs"
    }
  });

  _exports.default = _default;
});