define("ember-models-table/themes/ember-bootstrap-v3", ["exports", "ember-models-table/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class EmberBootstrap
   * @namespace Themes
   * @extends Themes.Bootstrap3
   */
  var _default = _bootstrap.default.extend({
    components: {
      'columns-dropdown': 'models-table/themes/ember-bootstrap-v3/columns-dropdown',
      'data-group-by-select': 'models-table/themes/ember-bootstrap-v3/data-group-by-select',
      'global-filter': 'models-table/themes/ember-bootstrap-v3/global-filter',
      'row-filtering-cell': 'models-table/themes/ember-bootstrap-v3/row-filtering-cell',
      'summary': 'models-table/themes/ember-bootstrap-v3/summary'
    }
  });

  _exports.default = _default;
});