define("ember-models-table/templates/components/models-table/columns-hidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h2NS5GKH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"td\"],[15,\"colspan\",[34,2]],[15,0,[34,0,[\"noDataCell\"]]],[12],[2,\"\\n\"],[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,3],null,[[\"columnsCount\",\"themeInstance\"],[[35,2],[35,0]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[35,0,[\"messages\",\"allColumnsAreHidden\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"html-safe\",\"columnsCount\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/columns-hidden.hbs"
    }
  });

  _exports.default = _default;
});