define("ember-models-table/templates/components/models-table/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n2Xjuzez",
    "block": "{\"symbols\":[\"opt\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"option\"],[15,2,[32,1,[\"value\"]]],[15,\"selected\",[30,[36,2],[[35,1],[32,1,[\"value\"]],[35,0]],null]],[12],[2,\"\\n    \"],[1,[32,1,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"type\",\"value\",\"is-equal\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/select.hbs"
    }
  });

  _exports.default = _default;
});