define("ember-models-table/templates/components/models-table/row-sorting-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M2fqTjfX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,10],null,[[\"column\",\"selectedItems\",\"expandedItems\",\"data\",\"themeInstance\",\"expandAllRows\",\"toggleAllSelection\",\"collapseAllRows\"],[[35,1],[35,8],[35,7],[35,6],[35,0],[35,5],[35,4],[35,3]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1,[\"componentForSortCell\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,9],[[35,1,[\"componentForSortCell\"]]],[[\"column\",\"selectedItems\",\"expandedItems\",\"data\",\"themeInstance\",\"expandAllRows\",\"toggleAllSelection\",\"collapseAllRows\"],[[35,1],[35,8],[35,7],[35,6],[35,0],[35,5],[35,4],[35,3]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[35,1,[\"title\"]]],[2,\"\\n\"],[6,[37,2],[[35,1,[\"useSorting\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[15,0,[31,[[30,[36,2],[[35,1,[\"sortAsc\"]],[35,0,[\"sort-asc\"]]],null],\" \",[30,[36,2],[[35,1,[\"sortDesc\"]],[35,0,[\"sort-desc\"]]],null]]]],[12],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"column\",\"if\",\"collapseAllRows\",\"toggleAllSelection\",\"expandAllRows\",\"data\",\"expandedItems\",\"selectedItems\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/row-sorting-cell.hbs"
    }
  });

  _exports.default = _default;
});