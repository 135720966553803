define("ember-models-table/templates/components/models-table/no-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zfrm9XSS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"td\"],[15,\"colspan\",[34,2]],[12],[2,\"\\n\"],[6,[37,3],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[35,0,[\"messages\",\"noDataToShow\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"html-safe\",\"realColumnsCount\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/no-data.hbs"
    }
  });

  _exports.default = _default;
});