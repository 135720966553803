define("ember-models-table/templates/components/models-table/table-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PqD1arF2",
    "block": "{\"symbols\":[\"tf\",\"column\",\"&default\"],\"statements\":[[6,[37,17],[[30,[36,16],null,[[\"goToPage\",\"clearFilters\",\"expandRow\",\"collapseRow\",\"expandAllRows\",\"collapseAllRows\",\"themeInstance\",\"selectedItems\",\"expandedItems\",\"visibleProcessedColumns\",\"data\"],[[35,15],[35,14],[35,13],[35,12],[35,11],[35,10],[35,9],[35,4],[35,3],[35,6],[35,0]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,2,[\"componentForFooterCell\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,5],[[32,2,[\"componentForFooterCell\"]]],[[\"selectedItems\",\"expandedItems\",\"data\",\"mappedSelectedItems\",\"mappedExpandedItems\",\"mappedData\"],[[35,4],[35,3],[35,0],[30,[36,2],[[32,2,[\"propertyName\"]],[30,[36,1],[[32,2,[\"propertyName\"]],[35,4]],null]],null],[30,[36,2],[[32,2,[\"propertyName\"]],[30,[36,1],[[32,2,[\"propertyName\"]],[35,3]],null]],null],[30,[36,2],[[32,2,[\"propertyName\"]],[30,[36,1],[[32,2,[\"propertyName\"]],[35,0]],null]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"td\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"data\",\"map-by\",\"if\",\"expandedItems\",\"selectedItems\",\"component\",\"visibleProcessedColumns\",\"-track-array\",\"each\",\"themeInstance\",\"collapseAllRows\",\"expandAllRows\",\"collapseRow\",\"expandRow\",\"clearFilters\",\"goToPage\",\"hash\",\"with\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/table-footer.hbs"
    }
  });

  _exports.default = _default;
});