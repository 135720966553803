define("ember-bootstrap/components/bs-modal/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (ensure-safe-component (bs-default @buttonComponent (component "bs-button"))) as |Button|}}
    <form class="modal-footer" ...attributes {{on "submit" (bs-default @onSubmit (bs-noop))}}>
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{#if @submitTitle}}
          <Button @onClick={{@onClose}}>{{bs-default @closeTitle "Ok"}}</Button>
          <Button @type={{bs-default @submitButtonType "primary"}} onClick={{@onSubmit}} disabled={{bs-default @submitDisabled false}}>{{@submitTitle}}</Button>
        {{else}}
          <Button @type="primary" @onClick={{@onClose}}>{{bs-default @closeTitle "Ok"}}</Button>
        {{/if}}
      {{/if}}
    </form>
  {{/let}}
  */
  {
    "id": "FFPHLrno",
    "block": "{\"symbols\":[\"Button\",\"@onClose\",\"@closeTitle\",\"@submitButtonType\",\"@onSubmit\",\"@submitDisabled\",\"@submitTitle\",\"&default\",\"&attrs\",\"@namedBlocksInfo\",\"@buttonComponent\"],\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,0],[[32,11],[30,[36,5],[\"bs-button\"],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"form\"],[24,0,\"modal-footer\"],[17,9],[4,[38,3],[\"submit\",[30,[36,0],[[32,5],[30,[36,2],null,null]],null]],null],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,4],[[32,10],\"default\",[27,[32,8]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,8,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,[32,1],[],[[\"@onClick\"],[[32,2]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[[32,3],\"Ok\"],null]]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,1],[[16,\"onClick\",[32,5]],[16,\"disabled\",[30,[36,0],[[32,6],false],null]]],[[\"@type\"],[[30,[36,0],[[32,4],\"primary\"],null]]],[[\"default\"],[{\"statements\":[[1,[32,7]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,[32,1],[],[[\"@type\",\"@onClick\"],[\"primary\",[32,2]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[[32,3],\"Ok\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"bs-default\",\"if\",\"bs-noop\",\"on\",\"-has-block\",\"component\",\"ensure-safe-component\",\"let\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-modal/footer.hbs"
    }
  });
  /**
  
   Modal footer element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalFooter
   @namespace Components
   @extends Glimmer.Component
   @public
   */

  /**
   * The title of the default close button. Will be ignored (i.e. no close button) if you provide your own block
   * template.
   *
   * @property closeTitle
   * @type string
   * @default 'Ok'
   * @public
   */

  /**
   * The title of the submit button (primary button). Will be ignored (i.e. no button) if set to `null` or if you provide
   * your own block template.
   *
   * @property submitTitle
   * @type string
   * @default null
   * @public
   */

  /**
   * Set to `true` to disable the submit button. If you bind this to some property that indicates if submitting is allowed
   * (form validation for example) this can be used to prevent the user from pressing the submit button.
   *
   * @property submitDisabled
   * @type boolean
   * @default false
   * @public
   */

  /**
   * The type of the submit button (primary button).
   *
   * @property submitButtonType
   * @type string
   * @default 'primary'
   * @public
   */

  /**
   * @property buttonComponent
   * @type {String}
   * @private
   */

  /**
   * The action to send to the parent modal component when the modal footer's form is submitted
   *
   * @event onSubmit
   * @public
   */

  /**
   * @event onClose
   * @public
   */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});