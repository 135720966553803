define("ember-composable-helpers/helpers/has-next", ["exports", "ember-composable-helpers/helpers/next", "ember-composable-helpers/utils/is-equal", "ember-composable-helpers/-private/get-value-array-and-use-deep-equal-from-params"], function (_exports, _next, _isEqual, _getValueArrayAndUseDeepEqualFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasNext = hasNext;

  function hasNext(currentValue, array) {
    var useDeepEqual = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    if (!array) {
      array = [];
    }

    var nextValue = (0, _next.next)(currentValue, array, useDeepEqual);
    var isNotSameValue = !(0, _isEqual.default)(nextValue, currentValue, useDeepEqual);
    return isNotSameValue && Ember.isPresent(nextValue);
  }

  var _default = Ember.Helper.helper(function (params) {
    var _getValueArrayAndUseD = (0, _getValueArrayAndUseDeepEqualFromParams.default)(params),
        currentValue = _getValueArrayAndUseD.currentValue,
        array = _getValueArrayAndUseD.array,
        useDeepEqual = _getValueArrayAndUseD.useDeepEqual;

    return hasNext(currentValue, array, useDeepEqual);
  });

  _exports.default = _default;
});