define("ember-models-table/components/models-table/themes/ember-bootstrap-v4/row-filtering-cell", ["exports", "ember-models-table/components/models-table/row-filtering-cell", "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v4/row-filtering-cell"], function (_exports, _rowFilteringCell, _rowFilteringCell2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rowFilteringCell.default.extend({
    layout: _rowFilteringCell2.default
  });

  _exports.default = _default;
});