define("ember-models-table/components/models-table-server-paginated", ["exports", "ember-models-table/components/models-table", "ember-models-table/templates/components/models-table"], function (_exports, _modelsTable, _modelsTable2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Table-component with pagination, sorting and filtering.
   *
   * It should be used when pagination, filtering and sorting are done on the server-side. Otherwise [models-table](Components.ModelsTable.html) should be used.
   *
   * This component extends the base models-table component. For the end user, it can be used (nearly) the same:
   *
   * ```hbs
   * {{models-table-server-paginated data=data columns=columns}}
   * ```
   *
   * Usage with block context:
   *
   * ```hbs
   * {{#models-table-server-paginated data=data columns=columns as |mt|}}
   *   {{mt.global-filter}}
   *   {{mt.columns-dropdown}}
   *   {{mt.table}}
   *   {{mt.footer}}
   * {{/models-table}}
   * ```
   *
   * ModelsTableServerPaginated yields references to the following contextual components:
   *
   * * [models-table/global-filter](Components.ModelsTableGlobalFilter.html) - global filter used for table data
   * * [models-table/columns-dropdown](Components.ModelsTableColumnsDropdown.html) - dropdown with list of options to toggle columns and column-sets visibility
   * * [models-table/table](Components.ModelsTableTable.html) - table with a data
   * * [models-table/footer](Components.ModelsTableFooter.html) - summary and pagination
   *
   * Check own docs for each component to get detailed info.
   *
   * ModelsTableServerPaginated has a lot of options you may configure, but there are two required properties called `data` and `columns`. First one contains data-query:
   *
   * ```js
   * model: function() {
   *  return this.store.query('my-model', {});
   * }
   * ```
   *
   * It will then take this query and extend it with pagination, sorting and filtering information. All other query parameters added in will remain untouched. Everything else works exactly the same - global filters, column filters etc. still use the same properties to control them. A few things to notice:
   *
   * - When using `filterWithSelect` for a column, you must use `predefinedFilterOptions`, because the automatic loading of possible filter values cannot work here.
   * - There is a new optional field `filteredBy` for columns, which works much like `sortedBy`: if set, this field will be used as query parameter, otherwise it will use the `propertyName`.
   * - Sorting will not use multipleColumnSorting, it will only sort by one column.
   * - If you set `sortedBy: false` on a column, sorting will be disabled for this column.
   *
   * There are a couple of things which can be configured to adapt to your API:
   *
   * ``` js
   * // The property on meta to load the pages count from.
   * metaPagesCountProperty: 'pagesCount',
   *
   * // The property on meta to load the total item count from.
   * metaItemsCountProperty: 'itemsCount',
   *
   * // The time to wait until new data is actually loaded.
   * // This can be tweaked to avoid making too many server requests.
   * debounceDataLoadTime: 500,
   *
   * // The query parameters to use for server side filtering / querying.
   * filterQueryParameters: {
   *   globalFilter: 'search',
   *   sort: 'sort',
   *   sortDirection: 'sortDirection',
   *   page: 'page',
   *   pageSize: 'pageSize'
   * },
   ```
   *
   * This default configuration would try to get the total page count from `model.get('meta.pagesCount')` and the total item count from `model.get('meta.itemsCount')`, and would then go on to build the following query:
   *
   * ``` js
   * columns: [
   *   {
   *     propertyName: 'name',
   *     filteredBy: 'model_name'
   *   }
   * ]
   *
   * // after searching globally for "searchtexthere"
   * // and in the name column for "filterforname",
   * // and going to page 2,
   * // the following query would be built:
   * ?page=2&pageSize=50&search=searchtexthere&sort=name&sortDirection=ASC&model_name=filterforname
   * ```
   *
   * @class ModelsTableServerPaginated
   * @namespace Components
   * @extends Components.ModelsTable
   */
  var _default = _modelsTable.default.extend({
    layout: _modelsTable2.default,

    /**
     * True if data is currently being loaded from the server.
     * Can be used in the template to e.g. display a loading spinner.
     *
     * @type boolean
     * @property isLoading
     * @default false
     * @private
     */
    isLoading: false,

    /**
     * True if last data query promise has been rejected.
     * Can be used in the template to e.g. indicate stale data or to e.g. show error state.
     *
     * @type boolean
     * @property isError
     * @default false
     * @private
     */
    isError: false,

    /**
     * The property on meta to load the pages count from.
     *
     * @type string
     * @property metaPagesCountProperty
     * @default 'pagesCount'
     */
    metaPagesCountProperty: 'pagesCount',

    /**
     * The property on meta to load the total item count from.
     *
     * @type {string}
     * @property metaItemsCountProperty
     * @default 'itemsCount'
     */
    metaItemsCountProperty: 'itemsCount',

    /**
     * The time to wait until new data is actually loaded.
     * This can be tweaked to avoid making too many server requests.
     *
     * @type number
     * @property debounceDataLoadTime
     * @default 500
     */
    debounceDataLoadTime: 500,

    /**
     * Determines if multi-columns sorting should be used
     *
     * @type boolean
     * @property multipleColumnsSorting
     * @default false
     */
    multipleColumnsSorting: false,

    /**
     * The query parameters to use for server side filtering / querying.
     *
     * @type object
     * @property filterQueryParameters
     */
    filterQueryParameters: Ember.computed({
      get: function get() {
        return {
          globalFilter: 'search',
          sort: 'sort',
          sortDirection: 'sortDirection',
          page: 'page',
          pageSize: 'pageSize'
        };
      },
      set: function set(k, v) {
        return v;
      }
    }),

    /**
     * @property observedProperties
     * @type string[]
     * @private
     */
    observedProperties: Ember.computed(function () {
      return ['currentPageNumber', 'sortProperties.[]', 'pageSize', 'filterString', 'processedColumns.@each.filterString'];
    }),

    /**
     * This is set during didReceiveAttr and whenever the page/filters change.
     *
     * @override
     * @property filteredContent
     * @default []
     * @private
     * @type object[]
     */
    filteredContent: null,

    /**
     * For server side filtering, visibleContent is same as the filtered content
     *
     * @override
     * @property visibleContent
     * @private
     * @type object[]
     */
    visibleContent: Ember.computed.alias('arrangedContent'),

    /**
     * For server side filtering, arrangedContent is same as the filtered content
     *
     * @override
     * @property arrangedContent
     * @private
     * @type object[]
     */
    arrangedContent: Ember.computed.alias('filteredContent'),

    /**
     * Can't be used within `models-table-server-paginated`. Back-end determines how to filter data
     *
     * @override
     * @property filteringIgnoreCase
     */
    filteringIgnoreCase: Ember.computed({
      set: function set() {
        (false && !(false) && Ember.assert('"filteringIgnoreCase" can\'t be used with "models-table-server-paginated"', false));
        return undefined;
      },
      get: function get() {
        return undefined;
      }
    }),

    /**
     * Can't be used within `models-table-server-paginated`. Back-end determines how to filter data
     *
     * @override
     * @property doFilteringByHiddenColumns
     */
    doFilteringByHiddenColumns: Ember.computed({
      set: function set() {
        (false && !(false) && Ember.assert('"doFilteringByHiddenColumns" can\'t be used with "models-table-server-paginated"', false));
        return undefined;
      },
      get: function get() {
        return undefined;
      }
    }),

    /**
     * The total content length is get from the meta information.
     * Set metaItemsCountProperty to change from which meta property this is loaded.
     *
     * @override
     * @type number
     * @property arrangedContentLength
     * @private
     */
    arrangedContentLength: Ember.computed('filteredContent.meta', function () {
      var itemsCountProperty = Ember.get(this, 'metaItemsCountProperty');
      var meta = Ember.get(this, 'filteredContent.meta') || {};
      return Ember.get(meta, itemsCountProperty) || 0;
    }),

    /**
     * The pages count is get from the meta information.
     * Set metaPagesCountProperty to change from which meta property this is loaded.
     *
     * @type number
     * @property pagesCount
     * @override
     * @private
     */
    pagesCount: Ember.computed('filteredContent.meta', function () {
      var pagesCountProperty = Ember.get(this, 'metaPagesCountProperty');
      var meta = Ember.get(this, 'filteredContent.meta') || {};
      return Ember.get(meta, pagesCountProperty) || 1;
    }),

    /**
     * The index of the last item that is currently being shown.
     *
     * @type number
     * @property lastIndex
     * @override
     * @private
     */
    lastIndex: Ember.computed('pageSize', 'currentPageNumber', 'arrangedContentLength', function () {
      var pageMax = parseInt(Ember.get(this, 'pageSize'), 10) * Ember.get(this, 'currentPageNumber');
      var itemsCount = Ember.get(this, 'arrangedContentLength');
      return Math.min(pageMax, itemsCount);
    }),

    /**
     * This function actually loads the data from the server.
     * It takes the store, modelName and query from the passed in data-object and adds page, sorting & filtering to it.
     *
     * @returns {Promise}
     * @method _loadData
     * @private
     */
    _loadData: function _loadData() {
      var _this = this;

      var data = Ember.get(this, 'data');
      var currentPageNumber = Ember.get(this, 'currentPageNumber');
      var pageSize = Ember.get(this, 'pageSize');
      var columns = Ember.get(this, 'processedColumns');
      var sortProperties = Ember.get(this, 'sortProperties');
      var filterString = Ember.get(this, 'filterString');

      if (!Ember.get(data, 'query')) {
        (false && Ember.warn('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data', false, {
          id: '#emt-query-usage'
        }));
        return;
      }

      var query = Object.assign({}, Ember.get(data, 'query'));
      var store = Ember.get(data, 'store');
      var modelName = Ember.get(data, 'type.modelName'); // Add pagination information

      query[Ember.get(this, 'filterQueryParameters.page')] = currentPageNumber;
      query[Ember.get(this, 'filterQueryParameters.pageSize')] = pageSize; // Add sorting information

      if (sortProperties && Ember.get(sortProperties, 'length')) {
        if (Ember.get(this, 'multipleColumnsSorting')) {
          query = this.multipleColumnsSortingWrapper(query, sortProperties);
        } else {
          if (sortProperties[0]) {
            var _sortProperties$0$spl = sortProperties[0].split(':'),
                _sortProperties$0$spl2 = _slicedToArray(_sortProperties$0$spl, 2),
                sortBy = _sortProperties$0$spl2[0],
                sortDirection = _sortProperties$0$spl2[1];

            query = this.singleColumnSortingWrapper(query, sortBy, sortDirection.toUpperCase());
          }
        }
      } else {
        delete query[[Ember.get(this, 'filterQueryParameters.sort')]];
        delete query[[Ember.get(this, 'filterQueryParameters.sortDirection')]];
      } // Add global filter


      var globalFilter = Ember.get(this, 'filterQueryParameters.globalFilter');

      if (filterString) {
        query[globalFilter] = filterString;
      } else {
        delete query[globalFilter];
      } // Add per-column filter


      if (Ember.get(this, 'useFilteringByColumns')) {
        columns.forEach(function (column) {
          var filter = Ember.get(column, 'filterString');

          var filterTitle = _this.getCustomFilterTitle(column);

          _this.setQueryFilter(query, column, filterTitle, filter);
        });
      }

      Ember.setProperties(this, {
        isLoading: true,
        isError: false
      });
      return this.doQuery(store, modelName, query).then(function () {
        return Ember.setProperties(_this, {
          isLoading: false,
          isError: false
        });
      }).catch(function () {
        return Ember.setProperties(_this, {
          isLoading: false,
          isError: true
        });
      });
    },

    /**
     * Do query-request to load new data
     *
     * You may override this method to add some extra behavior or even additional requests
     *
     * @method doQuery
     * @param {object} store
     * @param {string} modelName
     * @param {object} query
     * @returns {Promise}
     */
    doQuery: function doQuery(store, modelName, query) {
      var _this2 = this;

      return store.query(modelName, query).then(function (newData) {
        return Ember.set(_this2, 'filteredContent', newData);
      });
    },

    /**
     * Actually set the filter on a query.
     * This can be overwritten for special case handling.
     * Note that this will mutate the given query object!
     *
     * @param {object} query the query to mutate
     * @param {object} column the column that is filtering
     * @param {string} filterTitle the query param name for filtering
     * @param {*} filter the actual filter value
     * @returns {undefined}
     * @method setQueryFilter
     */
    setQueryFilter: function setQueryFilter(query, column, filterTitle, filter) {
      if (!Ember.isBlank(filter)) {
        query[filterTitle] = filter;
      } else {
        delete query[filterTitle];
      }
    },

    /**
     * Wrapper for sorting query when single column sorting is used
     *
     * @param {object} query parameters
     * @param {string} sortBy
     * @param {string} sortDirection
     * @returns {object} query parameters
     * @method singleColumnSortingWrapper
     */
    singleColumnSortingWrapper: function singleColumnSortingWrapper(query, sortBy, sortDirection) {
      query[Ember.get(this, 'filterQueryParameters.sort')] = sortBy;
      query[Ember.get(this, 'filterQueryParameters.sortDirection')] = sortDirection;
      return query;
    },

    /**
     * Wrapper for sorting query when multi columns sorting is used
     *
     * @param {object} query
     * @param {object} sortProperties
     * @returns {object} query parameters
     * @method multipleColumnsSortingWrapper
     */
    multipleColumnsSortingWrapper: function multipleColumnsSortingWrapper(query, sortProperties) {
      query[Ember.get(this, 'filterQueryParameters.sort')] = sortProperties.map(function (sortProp) {
        var _sortProp$split = sortProp.split(':'),
            _sortProp$split2 = _slicedToArray(_sortProp$split, 2),
            prop = _sortProp$split2[0],
            direction = _sortProp$split2[1];

        var sign = direction.toLowerCase() === 'desc' ? '-' : '';
        return "".concat(sign).concat(prop);
      }).join(',');
      return query;
    },

    /**
     * Customize filter title
     *
     * @method getCustomFilterTitle
     * @param {object} column
     * @returns {string} title
     */
    getCustomFilterTitle: function getCustomFilterTitle(column) {
      return Ember.get(column, 'filteredBy') || Ember.get(column, 'propertyName');
    },
    actions: {
      /**
       * @override
       * @method actions.sort
       * @param {ModelsTableColumn} column
       * @returns {undefined}
       */
      sort: function sort(column) {
        var sortMap = Ember.get(this, 'sortMap');
        var sortedBy = Ember.get(column, 'sortedBy') || Ember.get(column, 'propertyName');

        if (Ember.isNone(sortedBy)) {
          return;
        }

        var currentSorting = Ember.get(column, 'sorting');
        var newSorting = sortMap[currentSorting.toLowerCase()];
        var sortingArgs = [column, sortedBy, newSorting];

        if (Ember.get(this, 'multipleColumnsSorting')) {
          this._multiColumnsSorting.apply(this, sortingArgs);
        } else {
          this._singleColumnSorting.apply(this, sortingArgs);
        }

        this.userInteractionObserver();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'filteredContent', Ember.get(this, 'data'));
    },
    _addPropertyObserver: function _addPropertyObserver() {
      Ember.run.debounce(this, this._loadData, Ember.get(this, 'debounceDataLoadTime'));
    },
    willInsertElement: function willInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);

      var observedProperties = Ember.get(this, 'observedProperties');
      observedProperties.forEach(function (propertyName) {
        return _this3.addObserver(propertyName, _this3, '_addPropertyObserver');
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this4 = this;

      this._super.apply(this, arguments);

      var observedProperties = Ember.get(this, 'observedProperties');
      observedProperties.forEach(function (propertyName) {
        return _this4.removeObserver(propertyName, _this4, '_addPropertyObserver');
      });
    }
  });

  _exports.default = _default;
});