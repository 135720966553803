define("ember-models-table/components/models-table/themes/ember-bootstrap-v3/columns-dropdown", ["exports", "ember-models-table/components/models-table/columns-dropdown", "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/columns-dropdown"], function (_exports, _columnsDropdown, _columnsDropdown2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _columnsDropdown.default.extend({
    layout: _columnsDropdown2.default
  });

  _exports.default = _default;
});