define("ember-models-table/components/models-table/columns-hidden", ["exports", "ember-models-table/templates/components/models-table/columns-hidden"], function (_exports, _columnsHidden) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Table row used within [models-table/table-body](Components.ModelsTableTableBody.html).
   *
   * Component used when all columns are hidden and no data items are shown. Usually it contains a simple message.
   *
   * Usage example:
   *
   * ```hbs
   * {{#models-table data=data columns=columns as |mt|}}
   *   {{#mt.table as |table|}}
   *     {{#table.body as |body|}}
   *       {{#if body.allColumnsAreHidden}}
   *         {{#body.columns-hidden as |ch|}}
   *             Any message here
   *         {{/body.columns-hidden}}
   *       {{else}}
   *         {{! ... }}
   *       {{/if}}
   *     {{/table.header}}
   *     {{! ... }}
   *   {{/mt.table}}
   *   {{! .... }}
   * {{/models-table}}
   * ```
   *
   * @class ModelsTableColumnsHidden
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _columnsHidden.default,
    tagName: 'tr',

    /**
     * Bound from {{#crossLink "Components.ModelsTableTable/columnsCount:property"}}ModelsTable.columnsCount{{/crossLink}}
     *
     * @property columnsCount
     * @type number
     * @default null
     */
    columnsCount: null,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/themeInstance:property"}}ModelsTable.themeInstance{{/crossLink}}
     *
     * @property themeInstance
     * @type object
     * @default null
     */
    themeInstance: null
  });

  _exports.default = _default;
});