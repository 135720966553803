define("ember-models-table/templates/components/models-table/cell-content-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F5pGZ3Qf",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell-content-display.hbs"
    }
  });

  _exports.default = _default;
});