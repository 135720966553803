define("ember-models-table/templates/components/models-table/themes/semanticui/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+MJdlO7+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,11],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,10],null,[[\"anyFilterUsed\",\"summary\",\"clearFilters\",\"firstIndex\",\"lastIndex\",\"recordsCount\"],[[35,2],[35,1],[35,9],[35,8],[35,7],[35,6]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"form\"],[15,0,[34,0,[\"form\"]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[34,0,[\"formElementWrapper\"]]],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"\\n        \"],[1,[34,1]],[2,\"\\n        \"],[11,\"a\"],[24,6,\"#\"],[24,\"role\",\"button\"],[16,0,[30,[36,4],[\"clearFilters \",[30,[36,3],[[35,2],\"hidden transition\"],null]],null]],[4,[38,5],[[32,0],\"clearFilters\"],null],[12],[2,\"\\n          \"],[10,\"i\"],[15,0,[34,0,[\"clearAllFiltersIcon\"]]],[12],[13],[2,\"\\n          \"],[10,\"span\"],[14,0,\"emt-sr-only\"],[12],[1,[35,0,[\"messages\",\"clearAllFilters\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"summary\",\"anyFilterUsed\",\"unless\",\"concat\",\"action\",\"recordsCount\",\"lastIndex\",\"firstIndex\",\"clearFilters\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/semanticui/summary.hbs"
    }
  });

  _exports.default = _default;
});