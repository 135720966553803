define("ember-on-helper/utils/event-listener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SUPPORTS_EVENT_OPTIONS = void 0;
  _exports.addEventListener = addEventListener;
  _exports.addEventListenerOnce = addEventListenerOnce;
  _exports.removeEventListener = removeEventListener;

  /* eslint no-param-reassign: "off" */

  /**
   * Internet Explorer 11 does not support `once` and also does not support
   * passing `eventOptions`. In some situations it then throws a weird script
   * error, like:
   *
   * ```
   * Could not complete the operation due to error 80020101
   * ```
   *
   * This flag determines, whether `{ once: true }` and thus also event options in
   * general are supported.
   */
  var SUPPORTS_EVENT_OPTIONS = function () {
    try {
      var div = document.createElement('div');
      var counter = 0;
      div.addEventListener('click', function () {
        return counter++;
      }, {
        once: true
      });
      var event;

      if (typeof Event === 'function') {
        event = new Event('click');
      } else {
        event = document.createEvent('Event');
        event.initEvent('click', true, true);
      }

      div.dispatchEvent(event);
      div.dispatchEvent(event);
      return counter === 1;
    } catch (error) {
      return false;
    }
  }();
  /**
   * Registers an event for an `element` that is called exactly once and then
   * unregistered again. This is effectively a polyfill for `{ once: true }`.
   *
   * It also accepts a fourth optional argument `useCapture`, that will be passed
   * through to `addEventListener`.
   *
   * @param {Element} element
   * @param {string} eventName
   * @param {Function} callback
   * @param {boolean} [useCapture=false]
   */


  _exports.SUPPORTS_EVENT_OPTIONS = SUPPORTS_EVENT_OPTIONS;

  function addEventListenerOnce(element, eventName, callback) {
    var useCapture = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    function listener() {
      element.removeEventListener(eventName, listener, useCapture);
      callback();
    }

    element.addEventListener(eventName, listener, useCapture);
  }
  /**
   * Safely invokes `addEventListener` for IE11 and also polyfills the
   * `{ once: true }` and `{ capture: true }` options.
   *
   * All other options are discarded for IE11. Currently this is only `passive`.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
   *
   * @param {Element} element
   * @param {string} eventName
   * @param {Function} callback
   * @param {object} [eventOptions]
   */


  function addEventListener(element, eventName, callback, eventOptions) {
    var _callback = false
    /* DEBUG */
    && eventOptions && eventOptions.passive ? function (event) {
      event.preventDefault = function () {
        (false && !(false) && Ember.assert("ember-on-helper: You marked this listener as 'passive', meaning that you must not call 'event.preventDefault()'."));
      };

      return callback.call(this, event);
    } : callback;

    if (SUPPORTS_EVENT_OPTIONS) {
      element.addEventListener(eventName, _callback, eventOptions);
    } else if (eventOptions && eventOptions.once) {
      addEventListenerOnce(element, eventName, _callback, Boolean(eventOptions.capture));
    } else {
      element.addEventListener(eventName, _callback, Boolean(eventOptions && eventOptions.capture));
    }
  }
  /**
   * Since the same `capture` event option that was used to add the event listener
   * needs to be used when removing the listener, it needs to be polyfilled as
   * `useCapture` for IE11.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener
   *
   * @param {Element} element
   * @param {string} eventName
   * @param {Function} callback
   * @param {object} [eventOptions]
   */


  function removeEventListener(element, eventName, callback, eventOptions) {
    if (SUPPORTS_EVENT_OPTIONS) {
      element.removeEventListener(eventName, callback, eventOptions);
    } else {
      element.removeEventListener(eventName, callback, Boolean(eventOptions && eventOptions.capture));
    }
  }
});