define("ember-models-table/templates/components/models-table/themes/semanticui/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SJ58hPF6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,7],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,8],null,[[\"themeInstance\"],[[35,0]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"globalSearch \",[34,0,[\"globalFilterWrapper\"]]]]],[12],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[34,4]],[14,0,\"ui label\"],[12],[2,\"\\n      \"],[1,[35,0,[\"messages\",\"searchLabel\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"id\",\"type\",\"value\",\"enter\",\"class\",\"placeholder\"],[[35,4],\"text\",[35,1],[30,[36,3],[[32,0],\"noop\"],null],\"filterString\",[35,0,[\"messages\",\"searchPlaceholder\"]]]]]],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[15,0,[31,[\"clearFilterIcon \",[34,0,[\"clearFilterIcon\"]]]]],[14,\"role\",\"button\"],[15,\"aria-label\",[34,0,[\"messages\",\"clearGlobalFilter\"]]],[15,\"onclick\",[30,[36,3],[[32,0],[30,[36,2],[[35,1]],null],\"\"],null]],[12],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"value\",\"mut\",\"action\",\"inputId\",\"input\",\"globalFilterUsed\",\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/semanticui/global-filter.hbs"
    }
  });

  _exports.default = _default;
});