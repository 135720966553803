define("ember-models-table/components/models-table/themes/semanticui/pagination-numeric", ["exports", "ember-models-table/components/models-table/pagination-numeric", "ember-models-table/templates/components/models-table/themes/semanticui/pagination-numeric"], function (_exports, _paginationNumeric, _paginationNumeric2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paginationNumeric.default.extend({
    layout: _paginationNumeric2.default
  });

  _exports.default = _default;
});