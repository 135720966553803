define("ember-bootstrap-power-select/components/bs-form/element/control/power-select", ["exports", "ember-bootstrap/components/bs-form/element/control", "ember-bootstrap-power-select/templates/components/bs-form/element/control/power-select"], function (_exports, _control, _powerSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _control.default.extend({
    layout: _powerSelect.default,
    tagName: '',

    /**
     * @property powerSelectComponent
     * @type {String}
     */
    powerSelectComponent: 'power-select'
  });

  _exports.default = _default;
});