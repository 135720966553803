define("ember-models-table/components/models-table/themes/ember-semanticui/select", ["exports", "ember-models-table/templates/components/models-table/themes/ember-semanticui/select"], function (_exports, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _select.default,

    /**
     * @property clearable
     * @type boolean
     * @default false
     */
    clearable: false,

    /**
     * @property wide
     * @type boolean
     * @default false
     */
    wide: false,
    actions: {
      updateValue: function updateValue(val) {
        if (Ember.get(this, 'type') === 'number') {
          val = +val;
        }

        Ember.set(this, 'value', val);
      }
    }
  });

  _exports.default = _default;
});