define("ember-models-table/templates/components/models-table/grouped-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2zAUj61o",
    "block": "{\"symbols\":[\"cell\",\"&default\"],\"statements\":[[6,[37,4],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,2],[[35,1],\"column\"],null],[35,0]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"th\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"th\"],[15,\"colspan\",[32,1,[\"colspan\"]]],[15,\"rowspan\",[32,1,[\"rowspan\"]]],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"useDataGrouping\",\"displayGroupedValueAs\",\"is-equal\",\"and\",\"if\",\"groupedHeader\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/grouped-header.hbs"
    }
  });

  _exports.default = _default;
});