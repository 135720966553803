define("ember-math-helpers/helpers/random", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.random = random;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var min = Math.min,
      max = Math.max; // @see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed#max(0, min(MAX_DECIMALS, decimals))));

  var MAX_DECIMALS = 20; // 💡 Using Number.toFixed, we'll get rounding for free alongside
  // decimal precision. We'll default to whole-number rounding simply
  // by defaulting `decimals` to 0

  var DEFAULT_OPTS = {
    decimals: 0
  };
  /**
   * Executes `Math.random` with the provided upper-bounded randoms, lower- and upper-bounded randoms,
   * and the option to configure decimal precision.
   *
   * ```hbs
   * {{random}} or {{random decimals=4}}
   * ```
   *
   * ```hbs
   * {{random 42}} or {{random 42 decimals=4}}
   * ```
   *
   * ```hbs
   * {{random 21 1797}} or {{random 21 1797 decimals=4}}
   * ```
   *
   * @param {number} upperBound
   * @param {number} lowerBound
   * @param {object} { decimals } decimal precision value
   * @return {number} The random generated number
   */

  function random(params) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_OPTS,
        decimals = _ref.decimals;

    // no positional args, but only an options hash from named args
    if (_typeof(params) === 'object' && !Ember.isArray(params)) {
      decimals = typeof params.decimals !== 'undefined' ? params.decimals : DEFAULT_OPTS.decimals;
      return +Math.random().toFixed(max(0, min(MAX_DECIMALS, decimals)));
    } // one positional arg: treat it as an upper bound


    if (params && params.length === 1) {
      var _params = _slicedToArray(params, 1),
          upperBound = _params[0];

      return +(Math.random() * upperBound).toFixed(max(0, min(MAX_DECIMALS, decimals)));
    } // two positional args: use them to derive upper and lower bounds


    if (params && params.length === 2) {
      var _params2 = _slicedToArray(params, 2),
          lowerBound = _params2[0],
          _upperBound = _params2[1]; // for convenience, swap if a higher number is accidentally passed first


      if (_upperBound < lowerBound) {
        var _ref2 = [_upperBound, lowerBound];
        lowerBound = _ref2[0];
        _upperBound = _ref2[1];
      }

      return +(lowerBound + Math.random() * (_upperBound - lowerBound)).toFixed(max(0, min(MAX_DECIMALS, decimals)));
    } // no positional or named args: just return Math.random() w/ default decimal precision


    return +Math.random().toFixed(max(0, min(MAX_DECIMALS, decimals)));
  }

  var _default = Ember.Helper.helper(random);

  _exports.default = _default;
});