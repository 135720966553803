define("ember-models-table/templates/components/models-table/themes/bootstrap4/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dQ4IrH+4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,10],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,9],null,[[\"themeInstance\"],[[35,0]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[34,0,[\"globalFilterWrapper\"]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,1],[[35,0,[\"form\"]],\" globalSearch\"],null]],[12],[2,\"\\n      \"],[10,\"div\"],[15,0,[34,0,[\"inputGroup\"]]],[12],[2,\"\\n        \"],[10,\"label\"],[15,\"for\",[34,2]],[14,0,\"input-group-addon\"],[12],[1,[35,0,[\"messages\",\"searchLabel\"]]],[13],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"id\",\"type\",\"value\",\"class\",\"enter\",\"placeholder\"],[[35,2],\"text\",[35,4],[30,[36,1],[[35,0,[\"input\"]],\" filterString\"],null],[30,[36,3],[[32,0],\"noop\"],null],[35,0,[\"messages\",\"searchPlaceholder\"]]]]]],[2,\"\\n        \"],[10,\"span\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n          \"],[10,\"button\"],[15,0,[31,[\"clearFilterIcon \",[34,0,[\"buttonDefaultSmall\"]]]]],[15,\"disabled\",[30,[36,7],[[35,6],\"disabled\"],null]],[15,\"onclick\",[30,[36,3],[[32,0],[30,[36,8],[[35,4]],null],\"\"],null]],[14,4,\"button\"],[12],[2,\"\\n            \"],[10,\"i\"],[15,0,[34,0,[\"clearFilterIcon\"]]],[12],[13],[2,\"\\n            \"],[10,\"span\"],[14,0,\"emt-sr-only\"],[12],[1,[35,0,[\"messages\",\"clearGlobalFilter\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"concat\",\"inputId\",\"action\",\"value\",\"input\",\"globalFilterUsed\",\"unless\",\"mut\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/bootstrap4/global-filter.hbs"
    }
  });

  _exports.default = _default;
});