define("ember-models-table/components/models-table/themes/bootstrap4/global-filter", ["exports", "ember-models-table/templates/components/models-table/themes/bootstrap4/global-filter", "ember-models-table/components/models-table/global-filter"], function (_exports, _globalFilter, _globalFilter2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _globalFilter2.default.extend({
    layout: _globalFilter.default
  });

  _exports.default = _default;
});