define("ember-models-table/templates/components/models-table/cell-content-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dck8HvG4",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"type\",\"class\",\"value\"],[\"text\",[35,3,[\"input\"]],[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\",\"themeInstance\",\"input\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell-content-edit.hbs"
    }
  });

  _exports.default = _default;
});