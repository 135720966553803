define("ember-models-table/templates/components/models-table/row-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ghhJ5evd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"td\"],[15,\"colspan\",[34,3,[\"length\"]]],[12],[2,\"\\n\"],[6,[37,8],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,7],null,[[\"expandedRowComponent\",\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"themeInstance\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,6],[[35,5]],[[\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"themeInstance\"],[[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"clickOnRow\",\"index\",\"visibleProcessedColumns\",\"record\",\"expandedRowComponent\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/row-expand.hbs"
    }
  });

  _exports.default = _default;
});