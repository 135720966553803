define("ember-cli-flash/templates/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LPuJzHt2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[32,0],[35,1],[30,[36,4],[[32,0],\"close\"],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[35,1,[\"message\"]]],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert-progress\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"alert-progressBar\"],[15,5,[34,0]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"progressDuration\",\"flash\",\"showProgressBar\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "ember-cli-flash/templates/components/flash-message.hbs"
    }
  });

  _exports.default = _default;
});