define("ember-models-table/templates/components/models-table/cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j1Zsh13j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,18],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,19],null,[[\"record\",\"index\",\"column\",\"componentToRender\",\"expandRow\",\"collapseRow\",\"expandAllRows\",\"collapseAllRows\",\"editRow\",\"cancelEditRow\",\"themeInstance\",\"clickOnRow\",\"isExpanded\",\"isSelected\",\"isEditRow\",\"saveRow\",\"isColumnEditable\"],[[35,15],[35,14],[35,13],[35,16],[35,8],[35,7],[35,6],[35,5],[35,11],[35,9],[35,0],[35,4],[35,3],[35,2],[35,12],[35,10],[35,1]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,18],[[35,16]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,17],[[35,16]],[[\"record\",\"index\",\"column\",\"isEditRow\",\"editRow\",\"saveRow\",\"cancelEditRow\",\"expandRow\",\"collapseRow\",\"expandAllRows\",\"collapseAllRows\",\"clickOnRow\",\"isExpanded\",\"isSelected\",\"isColumnEditable\",\"themeInstance\"],[[35,15],[35,14],[35,13],[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"isColumnEditable\",\"isSelected\",\"isExpanded\",\"clickOnRow\",\"collapseAllRows\",\"expandAllRows\",\"collapseRow\",\"expandRow\",\"cancelEditRow\",\"saveRow\",\"editRow\",\"isEditRow\",\"column\",\"index\",\"record\",\"componentToRender\",\"component\",\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell.hbs"
    }
  });

  _exports.default = _default;
});