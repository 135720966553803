define("ember-cli-string-helpers/helpers/lowercase", ["exports", "ember-cli-string-helpers/utils/lowercase", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _lowercase, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lowercase = _exports.default = void 0;
  var lowercase = (0, _createStringHelper.default)(_lowercase.default);
  _exports.lowercase = lowercase;

  var _default = Ember.Helper.helper(lowercase);

  _exports.default = _default;
});