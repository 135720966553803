define("ember-cli-flash/utils/flash-message-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(configOverrides) {
    var addonDefaults = {
      timeout: 3000,
      extendedTimeout: 0,
      priority: 100,
      sticky: false,
      showProgress: false,
      type: 'info',
      types: ['success', 'info', 'warning', 'danger', 'alert', 'secondary'],
      injectionFactories: ['route', 'controller', 'view', 'component'],
      preventDuplicates: false
    };
    return Ember.assign(addonDefaults, configOverrides);
  }
});