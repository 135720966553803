define("ember-data-model-fragments/ext", ["exports", "@ember-data/store", "@ember-data/model", "ember-data/-private", "@ember-data/serializer/json", "ember-data-model-fragments/states", "ember-data-model-fragments/record-data", "ember-data-model-fragments/fragment"], function (_exports, _store, _model, _private, _json, _states, _recordData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "JSONSerializer", {
    enumerable: true,
    get: function get() {
      return _json.default;
    }
  });
  Object.defineProperty(_exports, "Model", {
    enumerable: true,
    get: function get() {
      return _model.default;
    }
  });
  Object.defineProperty(_exports, "Store", {
    enumerable: true,
    get: function get() {
      return _store.default;
    }
  });

  // eslint-disable-next-line ember/use-ember-data-rfc-395-imports
  function serializerForFragment(owner, normalizedModelName) {
    var serializer = owner.lookup("serializer:".concat(normalizedModelName));

    if (serializer !== undefined) {
      return serializer;
    } // no serializer found for the specific model, fallback and check for application serializer


    serializer = owner.lookup('serializer:-fragment');

    if (serializer !== undefined) {
      return serializer;
    } // final fallback, no model specific serializer, no application serializer, no
    // `serializer` property on store: use json-api serializer


    serializer = owner.lookup('serializer:-default');
    return serializer;
  }
  /**
    @module ember-data-model-fragments
  */


  var InternalModelPrototype = _private.InternalModel.prototype;
  var RecordDataPrototype = _private.RecordData.prototype;
  Object.assign(RecordDataPrototype, {
    eachFragmentKey: function eachFragmentKey(fn) {
      Object.keys(this.fragments).forEach(fn);
    },
    eachFragmentKeyValue: function eachFragmentKeyValue(fn) {
      var _this = this;

      this.eachFragmentKey(function (key) {
        var value = _this.getFragment(key);

        if (value) {
          fn(key, value);
        }
      });
    },
    getOwner: function getOwner() {
      return this._owner;
    },
    setOwner: function setOwner(value) {
      this._owner = value;
    },
    setName: function setName(value) {
      this._name = value;
    },
    getName: function getName() {
      return this._name;
    },
    getFragment: function getFragment(name) {
      return this.fragments[name];
    },
    didCommit: function didCommit(data) {
      if (this._attributes) {
        // willCommit was never called
        if (!this._inFlightAttributes) {
          this._inFlightAttributes = this._attributes;
        } else {
          Object.assign(this._inFlightAttributes, this._attributes);
        }

        this._attributes = null;
      }

      this._isNew = false;

      if (data) {
        if (data.relationships) {
          this._setupRelationships(data);
        }

        if (data.id) {
          // didCommit provided an ID, notify the store of it
          this.storeWrapper.setRecordId(this.modelName, data.id, this.clientId);
          this.id = (0, _private.coerceId)(data.id);
        }

        data = data.attributes; // Notify fragments that the record was committed

        this.eachFragmentKeyValue(function (key, fragment) {
          return fragment._didCommit(data[key]);
        });
      } else {
        this.eachFragmentKeyValue(function (key, fragment) {
          return fragment._didCommit();
        });
      }

      var changedKeys = this._changedKeys(data);

      Object.assign(this._data, this._inFlightAttributes, data);
      this._inFlightAttributes = null;

      this._updateChangedAttributes();

      return changedKeys;
    }
  });
  /**
    @class Store
    @namespace DS
  */

  _store.default.reopen({
    createRecordDataFor: function createRecordDataFor(type, id, lid, storeWrapper) {
      var identifier;

      if (false) {
        throw new Error('This version of Ember Data Model Fragments is incompatible with Ember Data Versions below 3.13. See matrix at https://github.com/lytics/ember-data-model-fragments#compatibility for details.');
      }

      if (true) {
        identifier = this.identifierCache.getOrCreateRecordIdentifier({
          type: type,
          id: id,
          lid: lid
        });
      } else {
        identifier = {
          type: type,
          id: id,
          clientId: lid
        };
      }

      return new _recordData.default(identifier, storeWrapper);
    },

    /**
      Create a new fragment that does not yet have an owner record.
      The properties passed to this method are set on the newly created
      fragment.
       To create a new instance of the `name` fragment:
       ```js
      store.createFragment('name', {
        first: 'Alex',
        last: 'Routé'
      });
      ```
       @method createRecord
      @param {String} type
      @param {Object} properties a hash of properties to set on the
        newly created fragment.
      @return {MF.Fragment} fragment
    */
    createFragment: function createFragment(modelName, props) {
      (false && !(this.isFragment(modelName)) && Ember.assert("The '".concat(modelName, "' model must be a subclass of MF.Fragment"), this.isFragment(modelName)));
      var internalModel;

      if (true) {
        var identifier = this.identifierCache.createIdentifierForNewRecord({
          type: modelName
        });
        internalModel = this._internalModelForResource(identifier);
      } else {
        var _identifier = {
          type: modelName,
          id: "".concat(Math.random()),
          lid: "".concat(Math.random())
        };
        internalModel = this._internalModelForResource(_identifier);
      } // Re-wire the internal model to use the fragment state machine


      internalModel.currentState = _states.default.empty;
      internalModel._recordData._name = null;
      internalModel._recordData._owner = null;
      internalModel.send('loadedData');
      var fragment = internalModel.getRecord();

      if (props) {
        fragment.setProperties(props);
      } // invoke the ready callback ( to mimic DS.Model behaviour )


      fragment.trigger('ready'); // Add brand to reduce usages of `instanceof`

      fragment._isFragment = true;
      return fragment;
    },

    /**
      Returns true if the modelName is a fragment, false if not
       @method isFragment
      @private
      @param {String} the modelName to check if a fragment
      @return {boolean}
    */
    isFragment: function isFragment(modelName) {
      if (modelName === 'application' || modelName === '-default') {
        return false;
      }

      var type = this.modelFor(modelName);
      return _fragment.default.detect(type);
    },
    serializerFor: function serializerFor(modelName) {
      // this assertion is cargo-culted from ember-data TODO: update comment
      (false && !(Ember.isPresent(modelName)) && Ember.assert('You need to pass a model name to the store\'s serializerFor method', Ember.isPresent(modelName)));
      (false && !(typeof modelName === 'string') && Ember.assert("Passing classes to store.serializerFor has been removed. Please pass a dasherized string instead of ".concat(modelName), typeof modelName === 'string'));
      var owner = Ember.getOwner(this);
      var normalizedModelName = (0, _private.normalizeModelName)(modelName);

      if (this.isFragment(normalizedModelName)) {
        return serializerForFragment(owner, normalizedModelName);
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
  /**
    @class Model
    @namespace DS
    */


  _model.default.reopen({
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var internalModel = (0, _fragment.internalModelFor)(this); // destroy the current state

      internalModel._recordData.resetFragments();
    }
  });

  _model.default.reopenClass({
    fields: Ember.computed(function () {
      var map = new Map();
      this.eachComputedProperty(function (name, meta) {
        if (meta.isFragment) {
          map.set(name, 'fragment');
        } else if (meta.isRelationship) {
          map.set(name, meta.kind);
        } else if (meta.isAttribute) {
          map.set(name, 'attribute');
        }
      });
      return map;
    }).readOnly()
  }); // Replace a method on an object with a new one that calls the original and then
  // invokes a function with the result


  function decorateMethod(obj, name, fn) {
    var originalFn = obj[name];

    obj[name] = function () {
      var value = originalFn.apply(this, arguments);
      return fn.call(this, value, arguments);
    };
  }
  /**
    Override parent method to snapshot fragment attributes before they are
    passed to the `DS.Model#serialize`.
  
    @method _createSnapshot
    @private
  */


  decorateMethod(InternalModelPrototype, 'createSnapshot', function createFragmentSnapshot(snapshot) {
    var attrs = snapshot._attributes;
    Object.keys(attrs).forEach(function (key) {
      var attr = attrs[key]; // If the attribute has a `_createSnapshot` method, invoke it before the
      // snapshot gets passed to the serializer

      if (attr && typeof attr._createSnapshot === 'function') {
        attrs[key] = attr._createSnapshot();
      }
    });
    return snapshot;
  });
  /**
    @class JSONSerializer
    @namespace DS
  */

  _json.default.reopen({
    /**
      Enables fragment properties to have custom transforms based on the fragment
      type, so that deserialization does not have to happen on the fly
       @method transformFor
      @private
    */
    transformFor: function transformFor(attributeType) {
      if (attributeType.indexOf('-mf-') !== 0) {
        return this._super.apply(this, arguments);
      }

      var owner = Ember.getOwner(this);
      var containerKey = "transform:".concat(attributeType);

      if (!owner.hasRegistration(containerKey)) {
        var match = attributeType.match(/^-mf-(fragment|fragment-array|array)(?:\$([^$]+))?(?:\$(.+))?$/);
        var transformName = match[1];
        var type = match[2];
        var polymorphicTypeProp = match[3];
        var transformClass = owner.factoryFor("transform:".concat(transformName));
        transformClass = transformClass && transformClass.class;
        transformClass = transformClass.extend({
          type: type,
          polymorphicTypeProp: polymorphicTypeProp,
          store: this.store
        });
        owner.register(containerKey, transformClass);
      }

      return owner.lookup(containerKey);
    },
    // We need to override this to handle polymorphic with a typeKey function
    applyTransforms: function applyTransforms(typeClass, data) {
      var _this2 = this;

      var attributes = Ember.get(typeClass, 'attributes');
      typeClass.eachTransformedAttribute(function (key, typeClass) {
        if (data[key] === undefined) {
          return;
        }

        var transform = _this2.transformFor(typeClass);

        var transformMeta = attributes.get(key);
        data[key] = transform.deserialize(data[key], transformMeta.options, data);
      });
      return data;
    }
  });
});