define("ember-models-table/components/models-table/themes/ember-bootstrap-v3/summary", ["exports", "ember-models-table/components/models-table/summary", "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/summary"], function (_exports, _summary, _summary2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _summary.default.extend({
    layout: _summary2.default
  });

  _exports.default = _default;
});