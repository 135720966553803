define("ember-models-table/components/models-table/themes/ember-bootstrap-v3/data-group-by-select", ["exports", "ember-models-table/components/models-table/data-group-by-select", "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/data-group-by-select"], function (_exports, _dataGroupBySelect, _dataGroupBySelect2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dataGroupBySelect.default.extend({
    layout: _dataGroupBySelect2.default
  });

  _exports.default = _default;
});