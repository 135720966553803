define("ember-models-table/templates/components/models-table/themes/ember-bootstrap-v4/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KXvMsnK1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,13],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,12],null,[[\"anyFilterUsed\",\"summary\",\"clearFilters\",\"firstIndex\",\"lastIndex\",\"recordsCount\"],[[35,3],[35,1],[35,11],[35,10],[35,9],[35,8]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],null,[[\"formLayout\",\"model\",\"onSubmit\"],[\"inline\",[32,0],[30,[36,2],[[32,0],\"noop\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[12],[1,[34,1]],[13],[2,\"\\n\"],[6,[37,6],null,[[\"class\",\"disabled\",\"type\",\"onClick\",\"buttonType\"],[[30,[36,5],[\"clearFilters border-0 \",[35,0,[\"buttonLink\"]]],null],[30,[36,4],[[35,3],\"disabled\"],null],\"bytton\",[30,[36,2],[[32,0],\"clearFilters\"],null],\"secondary\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[15,0,[34,0,[\"clearAllFiltersIcon\"]]],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"emt-sr-only\"],[12],[1,[35,0,[\"messages\",\"clearAllFilters\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"summary\",\"action\",\"anyFilterUsed\",\"unless\",\"concat\",\"bs-button\",\"bs-form\",\"recordsCount\",\"lastIndex\",\"firstIndex\",\"clearFilters\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v4/summary.hbs"
    }
  });

  _exports.default = _default;
});