define("ember-models-table/templates/components/models-table/themes/semanticui/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x+EA3ely",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/semanticui/select.hbs"
    }
  });

  _exports.default = _default;
});